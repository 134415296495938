import { Store } from 'src/app/core/store/store';
import { isDevMode } from '@angular/core';
import { apis } from './apis';

export const environment = {
  production: false,
  localstorage: {
    key: 'cloud_license_manager',
  },
 
  api: {
    core: {
      base: 'https://traq-dev.leftrightmind.com:8441/clm_dev',
      mockBase: 'https://leftrightmind.mockable.io',
    } as Store.APIConfig,
    ...apis
  },

  formats: {
    date: {
      // Moment JS date formats
      default: 'MM-DD-YYYY',
      long: 'D-MMM-YYYY',
      short: 'D-MMM-YYYY',
    },
    dateTime: {
      // Moment JS date formats
      default: 'MM-DD-YYYY HH:mm:ss',
    },
  },
};
